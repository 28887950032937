@charset "UTF-8";

.multiselect-container {
	position: absolute;
	list-style-type: none;
	margin: 0;
	padding: 0
}
.multiselect-container .input-group {
	margin: 5px
}
.multiselect-container>li {
	padding: 0
}
.multiselect-container>li>a.multiselect-all label {
	font-weight: 700
}
.multiselect-container>li.multiselect-group label {
	margin: 0;
	padding: 3px 20px 3px 20px;
	height: 100%;
	font-weight: 700
}
.multiselect-container>li.multiselect-group-clickable label {
	cursor: pointer
}
.multiselect-container>li>a {
	padding: 0
}
.multiselect-container>li>a>label {
	margin: 0;
	height: 100%;
	cursor: pointer;
	font-weight: 400;
	padding: 3px 20px 3px 40px
}
.multiselect-container>li>a>label.radio,
.multiselect-container>li>a>label.checkbox {
	margin: 0
}
.multiselect-container>li>a>label>input[type=checkbox] {
	margin-bottom: 5px
}
.btn-group>.btn-group:nth-child(2)>.multiselect.btn {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px
}
.form-inline .multiselect-container label.checkbox,
.form-inline .multiselect-container label.radio {
	padding: 3px 20px 3px 40px
}
.form-inline .multiselect-container li a label.checkbox input[type=checkbox],
.form-inline .multiselect-container li a label.radio input[type=radio] {
	margin-left: -20px;
	margin-right: 0
}


.btn-group.multi {
	width: 100%!important;
	margin:0 0 0.3em 0!important;
	padding:0 0.5em;
}


 .btn.multiselect {
color:#3a8f8d ;
   width: 100%!important;
	margin:0 auto!important;
  background-image: -webkit-linear-gradient(top, #f2f2f2 0%, #e6f2f2  100%);
  background-image: linear-gradient(to bottom, #f2f2f2 0%, #e6f2f2  100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe6f2f2', GradientType=0);
}


.btn-group.open .btn.dropdown-toggle, .btn.multiselect:hover, .btn.multiselect:active, .btn.multiselect:focus  {
    width: 100!important;
	margin:0 auto!important;
  background-image: -webkit-linear-gradient(top, #fafafa 0%, #e6f2f2 100%);
  background-image: linear-gradient(to bottom, #fafafa 0%, #e6f2f2  100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe6f2f2', GradientType=0);
}


